/* eslint-disable no-unused-vars */
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const rootElement = document.getElementById('root');
const app = <App />;

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, app);
} else {
  createRoot(rootElement).render(app);
}

// For react-snap
window.snapSaveState = () => ({
  __PRELOADED_STATE__: {}  // Add your state here if needed
});
