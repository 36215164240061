const seoConfig = {
  // Homepage
  home: {
    title: '+EV Bets - Finding Your Winning Bets',
    description: 'Finding the best betting opportunities with data science and advanced analytics',
    keywords: 'sports betting, analytics, NFL, NBA, MLB, NHL, betting odds, data science'
  },

  // Men's CBB pages
  mensCBB: {
    moneyline: {
      title: 'NCAA Basketball Moneyline Predictions | +EV Bets',
      description: 'Data-driven NCAA basketball moneyline predictions using Bart Torvik ratings. Find value bets with real-time odds comparison.',
      keywords: 'NCAA basketball betting, college basketball predictions, moneyline odds, Torvik ratings'
    },
    spread: {
      title: 'NCAA Men\'s Basketball Spread Predictions | +EV Bets',
      description: 'Data-driven NCAA Men\'s Basketball spread predictions using Bart Torvik ratings. Compare real-time odds and find +EV betting opportunities.',
      keywords: 'college basketball spread betting, NCAAB spread predictions, Bart Torvik ratings, spread odds, basketball betting analytics'
    },
    total: {
      title: 'NCAA Men\'s Basketball Totals Predictions | +EV Bets',
      description: 'Data-driven NCAA Men\'s Basketball over/under predictions using Bart Torvik ratings. Find the best total betting opportunities.',
      keywords: 'college basketball totals, NCAAB over/under, Bart Torvik ratings, total odds, basketball betting analytics'
    }
  },

  // NFL pages
  nfl: {
    moneyline: {
      title: 'NFL Moneyline Predictions | +EV Bets',
      description: 'Data-driven NFL moneyline predictions and betting analytics. Find the best value bets with real-time odds comparison.',
      keywords: 'NFL betting, moneyline predictions, football odds, NFL analytics, betting predictions'
    },
    spread: {
      title: 'NFL Spread Predictions | +EV Bets',
      description: 'Data-driven NFL spread predictions and betting analytics. Compare real-time odds and find +EV opportunities.',
      keywords: 'NFL spread betting, point spread predictions, football betting, NFL analytics'
    },
    total: {
      title: 'NFL Totals Predictions | +EV Bets',
      description: 'Data-driven NFL over/under predictions. Find the best total betting opportunities with real-time odds comparison.',
      keywords: 'NFL totals betting, over/under predictions, NFL game totals, football betting analytics'
    }
  },

  // MLB pages
  mlb: {
    moneyline: {
      title: 'MLB Moneyline Predictions | +EV Bets',
      description: 'Data-driven MLB moneyline predictions and betting analytics. Find value bets with real-time odds comparison.',
      keywords: 'MLB betting, baseball predictions, moneyline odds, baseball analytics'
    },
    spread: {
      title: 'MLB Run Line Predictions | +EV Bets',
      description: 'Data-driven MLB run line predictions and betting analytics. Find the best value with real-time odds comparison.',
      keywords: 'MLB run line, baseball spread betting, MLB predictions, baseball analytics'
    },
    total: {
      title: 'MLB Totals Predictions | +EV Bets',
      description: 'Data-driven MLB over/under predictions. Find the best total betting opportunities with real-time odds comparison.',
      keywords: 'MLB totals betting, baseball over/under, run totals, baseball analytics'
    },
    props: {
      title: 'MLB Player Props | +EV Bets',
      description: 'Data-driven MLB player prop predictions. Find the best prop betting opportunities with real-time odds comparison.',
      keywords: 'MLB props betting, baseball player props, strikeout props, baseball analytics'
    }
  },

  // NHL pages
  nhl: {
    moneyline: {
      title: 'NHL Moneyline Predictions | +EV Bets',
      description: 'Data-driven NHL moneyline predictions and betting analytics. Find value bets with real-time odds comparison.',
      keywords: 'NHL betting, hockey predictions, moneyline odds, hockey analytics, NHL picks'
    },
    spread: {
      title: 'NHL Puck Line Predictions | +EV Bets',
      description: 'Data-driven NHL puck line predictions and betting analytics. Find the best value with real-time odds comparison.',
      keywords: 'NHL puck line, hockey spread betting, NHL predictions, hockey analytics'
    },
    total: {
      title: 'NHL Totals Predictions | +EV Bets',
      description: 'Data-driven NHL over/under predictions. Find the best total betting opportunities with real-time odds comparison.',
      keywords: 'NHL totals betting, hockey over/under, goal totals, hockey analytics'
    }
  },

  // NCAAF pages
  ncaaf: {
    moneyline: {
      title: 'NCAAF Moneyline Predictions | +EV Bets',
      description: 'Data-driven college football moneyline predictions. Find value bets with real-time odds comparison.',
      keywords: 'college football betting, NCAAF predictions, moneyline odds, football analytics'
    },
    spread: {
      title: 'NCAAF Spread Predictions | +EV Bets',
      description: 'Data-driven college football spread predictions. Compare real-time odds and find +EV opportunities.',
      keywords: 'NCAAF spread betting, college football predictions, point spread, football analytics'
    },
    total: {
      title: 'NCAAF Totals Predictions | +EV Bets',
      description: 'Data-driven college football over/under predictions. Find the best total betting opportunities.',
      keywords: 'NCAAF totals betting, college football over/under, point totals, football analytics'
    }
  },

  // NBA pages
  nba: {
    moneyline: {
      title: 'NBA Moneyline Predictions | +EV Bets',
      description: 'Data-driven NBA moneyline predictions and betting analytics. Find value bets with real-time odds comparison.',
      keywords: 'NBA betting, basketball predictions, moneyline odds, NBA analytics, betting predictions'
    },
    spread: {
      title: 'NBA Spread Predictions | +EV Bets',
      description: 'Data-driven NBA spread predictions and betting analytics. Compare real-time odds and find +EV opportunities.',
      keywords: 'NBA spread betting, point spread predictions, basketball betting, NBA analytics'
    },
    total: {
      title: 'NBA Totals Predictions | +EV Bets',
      description: 'Data-driven NBA over/under predictions. Find the best total betting opportunities with real-time odds comparison.',
      keywords: 'NBA totals betting, basketball over/under, point totals, NBA analytics'
    }
  }
};

export default seoConfig;