import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import SEO from 'components/SEO';
import seoConfig from 'config/seo';
import { renderFormattedTime, renderFormattedDate, groupLogs, formatTimeAgo, groupGameLogsNCAAF } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavTabs from 'components/NavTabs';
import nbaRoutes from '../nbaRoutes';

const NBAtotal = () => {
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';

  useEffect(() => {
    fetch('/api/nbatotallog')
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(data => {
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);  // Update state with the fetched and sorted data
      })
      .catch(error => console.error('Error:', error));
  }, []); // Empty dependence

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  // const firstBookmaker = firstGroup && firstGroup[0] ? firstGroup[0].bookmaker : null;
  // const secondGroup = groupedLogs[1];
  // const secondBookmaker = secondGroup && secondGroup[1] ? secondGroup[1].bookmaker : null;
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };


  return (
    <>
      <SEO {...seoConfig.nba.total} />
      <Box sx={{ overflowX: 'hidden' }}>
        <Main bgcolor={'background.paper'}>
          <Container>
            <div>
              <header>
                <h1>Upcoming NBA Totals</h1>
                <p>Unfortunately, no projected totals from <a href="https://neilpaine.substack.com/" target="_blank" rel="noopener noreferrer">Neil Paine</a> (Paine), one of the most respected models for NBA predictions.</p>
                <p>But here are the current lines.</p>
                {/* <p>Projected totals from <a href="https://neilpaine.substack.com/" target="_blank" rel="noopener noreferrer">Neil Paine</a> (Paine), one of the most respected models for NBA predictions.</p> */}
                {/* <p>Expected Value (EV) calculated by converting the sportsbook's total and comparing to the Paine's projected totals. Usually it's a percentage, for now it's the point differntial. +3 means exepceted to go over by 3 points, -3 expects the under.</p> */}
                {/* <p>Numbers in green suggest positive expected value from a betting perspective, these are +EV Bets.</p> */}
              </header>
              <p><i>Lines as of {timeSinceUpdate}, refresh every 2-4.</i></p>
            </div>
            <div><NavTabs routes={nbaRoutes} /></div>
            {logs.length === 0 ? (
              <div><b>Please check back soon, currently waiting on the next Paine update. Lines will be avialable soon after.</b></div>
            ) : (
              <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
                <Table sx={{ minWidth: 400}} aria-label="nfl-total-table">
                  <TableHead sx={{ bgcolor: 'grey.200' }}>
                    <TableRow>
                      <TableCell><strong>Game</strong></TableCell>
                      {/* <TableCell {...headerStyling}><strong>Paine<br />Total</strong></TableCell> */}
                      <TableCell {...headerStyling}><strong>DraftKings<br />Total</strong></TableCell>
                      {/* <TableCell {...headerStyling}><strong>DraftKings<br />EV</strong></TableCell> */}
                      <TableCell {...headerStyling}><strong>FanDuel<br />Total</strong></TableCell>
                      {/* <TableCell {...headerStyling}><strong>FanDuel<br />EV</strong></TableCell> */}
                      <TableCell {...headerStyling}><strong>Tipoff</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupGameLogsNCAAF(logs).map((gameLogs, index) => {
                      // console.log(`Game ${index} logs:`, gameLogs); // Log each game's logs
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <Typography variant={typographyVariant}>{gameLogs[0]?.away_team || gameLogs[1].away_team}</Typography>
                            <Typography variant={typographyVariant}>{gameLogs[0]?.home_team || gameLogs[1].home_team}</Typography>
                          </TableCell>
                          {/* <TableCell align="right">
                            <Typography variant={typographyVariant}>{gameLogs[0]?.torvik_total_points || gameLogs[1].torvik_total_points}</Typography>
                          </TableCell> */}
                          <TableCell align="right">
                            <Typography variant={typographyVariant}>
                              {gameLogs[0].totals_point !== undefined && gameLogs[0].totals_point !== '--' ? `o${gameLogs[0].totals_point}` : gameLogs[0].totals_point} 
                              {gameLogs[0].over_price !== '--' ? (gameLogs[0].over_price > 0 ? '+' : '') + gameLogs[0].over_price : gameLogs[0].over_price}
                            </Typography>
                            <Typography variant={typographyVariant}>
                              {gameLogs[0].totals_point !== undefined && gameLogs[0].totals_point !== '--' ? `u${gameLogs[0].totals_point}` : gameLogs[0].totals_point} 
                              {gameLogs[0].under_price !== '--' ? (gameLogs[0].under_price > 0 ? '+' : '') + gameLogs[0].under_price : gameLogs[0].under_price}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="right">
                            <Typography variant={typographyVariant} style={{ color: gameLogs[0]?.point_diff >= 3 || gameLogs[0]?.point_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[0]?.point_diff >= 3 || gameLogs[0]?.point_diff < -3 ? 'bold' : 'normal' }} > {gameLogs[0]?.point_diff !== undefined && gameLogs[0]?.point_diff !== '--'  ? `${gameLogs[0].point_diff > 0 ? '+' : ''}${gameLogs[0].point_diff}` : '--'}</Typography>
                          </TableCell> */}
                          <TableCell align="right">
                            <Typography variant={typographyVariant}>
                              {gameLogs[1].totals_point !== undefined && gameLogs[1].totals_point !== '--' ? `o${gameLogs[1].totals_point}` : gameLogs[1].totals_point} 
                              {gameLogs[1].over_price !== '--' ? (gameLogs[1].over_price > 0 ? '+' : '') + gameLogs[1].over_price : gameLogs[1].over_price}
                            </Typography>
                            <Typography variant={typographyVariant}>
                              {gameLogs[1].totals_point !== undefined && gameLogs[1].totals_point !== '--' ? `u${gameLogs[1].totals_point}` : gameLogs[1].totals_point} 
                              {gameLogs[1].under_price !== '--' ? (gameLogs[1].under_price > 0 ? '+' : '') + gameLogs[1].under_price : gameLogs[1].under_price}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="right">
                            <Typography variant={typographyVariant} style={{ color: gameLogs[1]?.point_diff >= 3 || gameLogs[1]?.point_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[1]?.point_diff >= 3 || gameLogs[1]?.point_diff < -3 ? 'bold' : 'normal' }} > {gameLogs[1]?.point_diff !== undefined && gameLogs[1]?.point_diff !== '--'  ? `${gameLogs[1].point_diff > 0 ? '+' : ''}${gameLogs[1].point_diff}` : '--'}</Typography>
                          </TableCell> */}
                          <TableCell align="right">
                            <Typography variant={typographyVariant}> {renderFormattedDate(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                            <Typography variant={typographyVariant}> {renderFormattedTime(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                          </TableCell>
                        </TableRow>
                      );}
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Container>
        </Main>
      </Box>
    </>
  );
};

export default NBAtotal;